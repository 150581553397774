import React, { useState, useContext, useEffect } from 'react'
import { Button } from '@material-ui/core'
import './Footer.css'
import AutopltContext from '../../../../product/context/autopilot/autopltContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { DPW_LOGO, DPW_LOGO_WHITE, LOGO } from '../../../../Utils'
import { mapData } from '../../../../product/common/components'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
const Footer = () => {
    const { t } = useTranslation()
    const autopltContext = useContext(AutopltContext)

    const { addCustomer, responseStatus: responseStatusAutoPlt } = autopltContext

    const validationArray = Yup.object({
        email: Yup.string().email(t('invalid_email_format')).required(t('required')),
    })

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            addCustomer(values)
        },
    })

    const subscribe = [
        {
            label: t('subscribe_to_our_mailing_list'),
            placeholder: t('enter_your_email_address'),
            class: 'subsInp',
            type: 'text',
            variant: 'filled',
            name: 'email',
            formik: formik,
        },
    ]

    useEffect(() => {
        if (responseStatusAutoPlt) {
            if (responseStatusAutoPlt.from === 'addCustomer') {
                if (responseStatusAutoPlt.status === 'success') {
                    formik.values.email = ''
                }
            }
        }
    }, [responseStatusAutoPlt])

    return (
        <footer className="noPrint">
            <div className="footCnt ">
                <div className="customContainer d-flex justify-content-between align-items-center flex-wrap h-100">
                    <div className="footLinks pt-4">
                        <div className="footLogo d-flex">
                            <img src={DPW_LOGO} alt="DP World Auctions Logo" />
                        </div>
                        <ul>
                            <li>
                                <Link to="/">{t('home')}</Link>
                            </li>
                            <li>
                                <Link to="/about">{t('about')}</Link>
                            </li>
                            <li>
                                <Link to="/guidelines">{t('guideline')}</Link>
                            </li>
                            <li>
                                <Link to="/contact">{t('contact')}</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="footMisc">
                        <h5 className="hashTag">{t('#DPWorldUAE')}</h5>
                        <ul className="footSocial d-flex justify-content-start">
                            <li>
                                <a
                                    href="https://www.dpworld.com/en/uae"
                                    target="_blank"
                                    without
                                    rel="noreferrer"
                                >
                                    <img src="/assets/svg/globe_black.svg" alt="weblink logo" />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.instagram.com/dpworlduae/"
                                    target="_blank"
                                    without
                                    rel="noreferrer"
                                >
                                    <img src="/assets/svg/ig_black.svg" alt="Instagram logo" />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://twitter.com/dpworlduae"
                                    target="_blank"
                                    without
                                    rel="noreferrer"
                                >
                                    <img src="/assets/svg/tw_black.svg" alt="Twitter logo" />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.facebook.com/DPWorldUAE/"
                                    target="_blank"
                                    without
                                    rel="noreferrer"
                                >
                                    <img src="/assets/svg/fb_black.svg" alt="Facebook logo" />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.linkedin.com/company/dp-world"
                                    target="_blank"
                                    without
                                    rel="noreferrer"
                                >
                                    <img src="/assets/svg/in_black.svg" alt="LinkedIn logo" />
                                </a>
                            </li>
                        </ul>
                        <h6 className="poweredBy">{t('powered_by_dp_world')}</h6>
                        {/* <div className="footSubscribe">
                        <form
                            onSubmit={formik.handleSubmit}
                            autoComplete="nofill"
                            className="form-inline footSubsForm"
                        >
                            {Object.values(mapData(subscribe))}

                            <Button className="btn" type="submit">
                                <span
                                    className={`material-icons ${
                                        document.body.dir === 'rtl' && 'rtl'
                                    }`}
                                >
                                    send
                                </span>
                            </Button>
                        </form>
                        <small>{t('stay_in_touch_with_us_for_the_new_products')}</small>
                    </div> */}
                    </div>
                </div>
                <p className="footCopyright text-center px-4" dir="ltr">
                    © {t('dp_world_auction')} {new Date().getFullYear()}.{t('all_rights_reserved')}
                </p>
            </div>
        </footer>
    )
}

export default Footer
