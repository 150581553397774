import React, { useEffect, useState, useContext } from 'react'
import './App.css'
import Routes from './routes'
import { BrowserRouter as Router } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import { I18nextProvider } from 'react-i18next'
import AuthState from './product/context/auth/authState'
import CustomCommonState from './custom/context/common/commonState'
import ProductCommonState from './product/context/common/commonState'
import UserState from './product/context/user/userState'
import AlertState from './product/context/alert/alertState'
import ProductState from './product/context/product/productState'
import InvoiceState from './product/context/invoice/invoiceState'
import DepositState from './product/context/deposit/depositState'
import AuctionState from './product/context/auction/auctionState'
import CreditState from './product/context/credit/creditState'
import OfflineState from './product/context/offline/offlineState'
import CartState from './product/context/cart/cartState'
import BuyerState from './product/context/buyer/buyerState'
import AutopltState from './product/context/autopilot/autopltState'
import PayState from './product/context/payment/payState'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { SnackbarProvider } from 'notistack'
import Alerts from './product/common/alert'
import BuynowState from './product/context/buynow/buynowState'
import AppointmentState from './product/context/appointment/appointmentState'
import RosoomState from './product/context/payment/rosoom/rosoomState'
import i18n from './product/common/language-i18n'
import withRoot from './withRoot'
import { create } from 'jss'
import rtl from 'jss-rtl'
import {
    MuiThemeProvider,
    StylesProvider,
    createMuiTheme,
    jssPreset,
    useTheme,
} from '@material-ui/core/styles'
import CustomCommonContext from './custom/context/common/commonContext'

global.site_url = process.env.REACT_APP_DOMAIN
global.images_url = global.site_url + '/uploads/product/'
global.ignoreStates = []
global.ignoreCountry = []
global.defaultCountry = 'AE'

const App = () => {
    const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
    // const customCommonContext = useContext(CustomCommonContext)
    // const { currentLanguage } = customCommonContext

    const [currLang, setCurrLang] = useState()
    const theme = useTheme()
    const ltrTheme = createMuiTheme({ direction: 'ltr' })
    const rtlTheme = createMuiTheme({ direction: 'rtl' })
    useEffect(() => {
        // localStorage.removeItem('currLanguage')
        // localStorage.setItem('currLanguage', document.body.dir)
        // setCurrLang(`${localStorage.i18nextLng == 'for_english' ? 'ltr' : 'rtl'}`)
        // setCurrLang(`${localStorage.i18nextLng == 'for_english' ? 'ltr' : 'rtl'}`)
        // console.log(localStorage.currLanguage, localStorage.i18nextLng, currLang, 'i18n.language')

        // localStorage.i18nextLng == 'for_english'
        //     ? (theme.direction = 'ltr')
        //     : (theme.direction = 'rtl')

        setCurrLang(localStorage.currLanguage == 'for_english' ? rtlTheme : ltrTheme)

        // console.log(theme.direction, 'theme.direction')
    }, [localStorage.currLanguage])

    return (
        <CookiesProvider>
            <StylesProvider jss={jss}>
                <I18nextProvider i18n={i18n}>
                    <CustomCommonState>
                        {/* <StylesProvider jss={jss}>
                        <MuiThemeProvider
                            theme={createMuiTheme({
                                direction: currLang,
                            })}
                        > */}
                        <ProductCommonState>
                            <AuthState>
                                <AlertState>
                                    <UserState>
                                        <ProductState>
                                            <AuctionState>
                                                <BuynowState>
                                                    <CartState>
                                                        <AppointmentState>
                                                            <CreditState>
                                                                <RosoomState>
                                                                    <PayState>
                                                                        <OfflineState>
                                                                            <InvoiceState>
                                                                                <DepositState>
                                                                                    <BuyerState>
                                                                                        <AutopltState>
                                                                                            <MuiPickersUtilsProvider
                                                                                                utils={
                                                                                                    MomentUtils
                                                                                                }
                                                                                            >
                                                                                                <SnackbarProvider
                                                                                                    maxSnack={
                                                                                                        3
                                                                                                    }
                                                                                                >
                                                                                                    <div className="App">
                                                                                                        <Alerts />
                                                                                                        <Router>
                                                                                                            <Routes />
                                                                                                        </Router>
                                                                                                    </div>
                                                                                                </SnackbarProvider>
                                                                                            </MuiPickersUtilsProvider>
                                                                                        </AutopltState>
                                                                                    </BuyerState>
                                                                                </DepositState>
                                                                            </InvoiceState>
                                                                        </OfflineState>
                                                                    </PayState>
                                                                </RosoomState>
                                                            </CreditState>
                                                        </AppointmentState>
                                                    </CartState>
                                                </BuynowState>
                                            </AuctionState>
                                        </ProductState>
                                    </UserState>
                                </AlertState>
                            </AuthState>
                        </ProductCommonState>
                        {/* </MuiThemeProvider>
                    </StylesProvider> */}
                    </CustomCommonState>
                </I18nextProvider>
            </StylesProvider>
        </CookiesProvider>
    )
}

export default withRoot(App)
