import React, { useState, useContext, useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { LOGO, BRAND_LOGO, DPW_LOGO_WHITE, DPW_LOGO } from '../../../../Utils'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
    Badge,
    Button,
    Divider,
    ListItem,
    Menu,
    MenuItem,
    SwipeableDrawer,
} from '@material-ui/core'
import AuthContext from '../../../../product/context/auth/authContext'
import CommonContext from '../../../../product/context/common/commonContext'
import CartContext from '../../../../product/context/cart/cartContext'
import AlertContext from '../../../../product/context/alert/alertContext'
import { handleRedirectInternal, useCustomMediaQuery } from '../../../../product/common/components'
import './Header.css'
import AutopltContext from '../../../../product/context/autopilot/autopltContext'
import UserContext from '../../../../product/context/user/userContext'
import ProductContext from '../../../../product/context/product/productContext'
import BidHistory from '../../../components/organisms/BidHistory'
import TopHeader from './TopHeader'
import LanguageSwitch from '../LanguageSwitch'
import BuynowContext from '../../../../product/context/buynow/buynowContext'
import PayContext from '../../../../product/context/payment/payContext'
import CreditContext from '../../../../product/context/credit/creditContext'
import OfflineContext from '../../../../product/context/offline/offlineContext'
import Loaders from '../Loaders'
import { Popover } from '@material-ui/core'

const Header = () => {
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const commonContext = useContext(CommonContext)
    const productContext = useContext(ProductContext)
    const payContext = useContext(PayContext)
    const cartContext = useContext(CartContext)
    const creditContext = useContext(CreditContext)
    const offlineContext = useContext(OfflineContext)
    const userContext = useContext(UserContext)
    const autopltContext = useContext(AutopltContext)
    const buynowContext = useContext(BuynowContext)
    const { t } = useTranslation()
    const { setAlert } = alertContext
    const {
        user,
        isAuthenticated,
        loadPendingCount,
        isAdmin,
        cartCount,
        logout,
        responseStatus: responseStatusAuth,
        clearResponse: clearResponseAuth,
    } = authContext

    const [state, setState] = useState({
        right: false,
        top: false,
    })
    const history = useHistory()
    const [anchormenu, setanchormenu] = useState(null)
    const [anchorcart, setanchorcart] = useState(null)

    const {
        responseStatus: responseStatusProduct,
        clearResponse: clearResponseProduct,
    } = productContext
    const {
        responseStatus: responseStatusPayment,
        clearResponse: clearResponsePayment,
    } = payContext

    const { responseStatus: responseStatusCart, clearResponse: clearResponseCart } = cartContext

    const { responseStatus: responseStatusUser, clearResponse: clearResponseUser } = userContext
    const {
        responseStatus: responseStatusCredit,
        clearResponse: clearResponseCredit,
    } = creditContext

    const {
        responseStatus: responseStatusOffline,
        clearResponse: clearResponseOffline,
    } = offlineContext

    const {
        responseStatus: responseStatusAutoPlt,
        clearResponse: clearResponseAutoPlt,
    } = autopltContext

    const {
        responseStatus: responseStatusBuyNow,
        clearResponse: clearResponseBuyNow,
        getAllCartProducts,
        search_allproducts,
    } = buynowContext

    const { searchValue, setSearchValue, isLoading } = commonContext
    const [searchText, setSearchText] = useState('')
    let [cartProducts, setCartProducts] = useState([])
    const [search, setSearch] = useState({})

    useEffect(() => {
        if (isAuthenticated) {
            getAllCartProducts(search)
        }
    }, [isAuthenticated])

    useEffect(() => {
        setCartProducts(search_allproducts.cartrecords.length ? search_allproducts.cartrecords : [])
    }, [search_allproducts])

    useEffect(() => {
        setCartProducts([])
        return () => {
            setCartProducts([])
        }
    }, [])

    useEffect(() => {
        if (responseStatusUser) {
            setAlert(responseStatusUser.message, responseStatusUser.status)
            clearResponseUser()
        }
        if (responseStatusProduct) {
            setAlert(responseStatusProduct.message, responseStatusProduct.status)
            clearResponseProduct()
        }
        if (responseStatusAutoPlt) {
            setAlert(responseStatusAutoPlt.message, responseStatusAutoPlt.status)
            clearResponseAutoPlt()
        }
        if (responseStatusAuth) {
            setAlert(responseStatusAuth.message, responseStatusAuth.status)
            clearResponseAutoPlt()
        }
        if (responseStatusBuyNow) {
            setAlert(responseStatusBuyNow.message, responseStatusBuyNow.status)
            clearResponseBuyNow()
        }
        if (responseStatusPayment) {
            setAlert(responseStatusPayment.message, responseStatusPayment.status)
            clearResponsePayment()
        }
        if (responseStatusCart) {
            setAlert(responseStatusCart.message, responseStatusCart.status)
            clearResponseCart()
        }
        if (responseStatusCredit) {
            setAlert(responseStatusCredit.message, responseStatusCredit.status)
            clearResponseCredit()
        }
        if (responseStatusOffline) {
            setAlert(responseStatusOffline.message, responseStatusOffline.status)
            clearResponseOffline()
        }
    }, [
        responseStatusUser,
        responseStatusProduct,
        responseStatusAutoPlt,
        responseStatusAuth,
        responseStatusBuyNow,
        responseStatusPayment,
        responseStatusCart,
        responseStatusCredit,
        responseStatusOffline,
    ])

    useEffect(() => {
        if (responseStatusPayment) {
            if (responseStatusPayment.from === 'payment') {
                if (responseStatusPayment.status === 'success') {
                    if (responseStatusPayment.data && responseStatusPayment.data.url) {
                        const newWindow = window.open(
                            responseStatusPayment.data.url,
                            '_self',
                            'noopener,noreferrer',
                        )
                        if (newWindow) newWindow.opener = null
                    }
                }
            }
        }
    }, [responseStatusPayment])

    // useEffect(() => {
    //     if (searchValue) {
    //         handleRedirectInternal(history, `search`)
    //     }
    // }, [searchValue])

    const onLogOut = () => {
        logout()
        setAlert('Logged out successfully', 'success')
        handleRedirectInternal(history, '')
        setanchormenu(null)
        setanchorcart(null)
        setSearchText('')
    }

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    const handleCart = (event) => {
        setanchorcart(event.currentTarget)
    }
    const handleMenu = (event) => {
        setanchormenu(event.currentTarget)
    }

    const handleClose = (history, path) => {
        setanchormenu(null)
        setanchorcart(null)
        if (history && path && path !== 'backdropClick') {
            console.log('history', history, path)
            handleRedirectInternal(history, path)
        }
    }

    const getSearchBarObjet = (value) => {
        return {
            limit: 12,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            filters: {
                searchbar: {
                    value: value,
                    type: 'like',
                    field: 'p.title,p.desc_proc',
                },
            },
        }
    }

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value)
    }

    const handleSearchButtonClick = () => {
        if (searchText != '') {
            setSearchValue(getSearchBarObjet(searchText))
            setState({ ...state, top: false })
            if (location.pathname != '/search') {
                handleRedirectInternal(history, 'search')
            }
        } else {
            // setSearch({
            //     ...search,
            //     filters: {
            //         ...search.filters,
            //         searchbar: {
            //             ...search.filters.searchbar,
            //             value: searchValue.filters.searchbar.value,
            //         },
            //     },
            // })
        }
    }

    const handleSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            setSearchValue(getSearchBarObjet(searchText))
            if (location.pathname != '/search') {
                handleRedirectInternal(history, 'search')
            }
        }
    }

    useEffect(() => {
        if (searchValue && searchValue.filters && searchValue.filters.searchbar) {
            setSearchText(searchValue.filters.searchbar.value)
        }
    }, [searchValue])

    useEffect(() => {
        if (isAuthenticated) {
            let auctionFilter = {
                table: 'buynow',
                field: 'id',
                extraField: '',
                filters: {
                    buynow_autype: {
                        value: 'live',
                        type: 'in',
                        field: 'b.buynow_autype',
                    },
                    paid: {
                        value: '0',
                        type: 'in',
                        field: 'b.paid',
                    },
                    partial: {
                        value: '0',
                        type: 'in',
                        field: 'b.partial',
                    },
                },
            }
            let buyNowFilter = {
                table: 'cart_temp',
                field: 'cart_id',
                extraField: '',
                filters: {
                    paid: {
                        value: '0',
                        type: 'in',
                        field: 'crt.paid',
                    },
                },
            }

            loadPendingCount(buyNowFilter, 'buynowCount')

            loadPendingCount(auctionFilter, 'auctionCount')
        }
    }, [isAuthenticated, history.location.pathname])

    const [count, setCount] = useState({
        auctionCount: 0,
        buynowCount: 0,
    })

    useEffect(() => {
        if (cartCount?.auctionCount) {
            setCount({ ...count, auctionCount: cartCount.auctionCount.totalRecords })
        }
        if (cartCount?.buynowCount) {
            setCount({ ...count, buynowCount: cartCount.buynowCount.totalRecords })
        }
    }, [cartCount])

    return (
        <>
            {useCustomMediaQuery('(min-width: 1024px)') ? (
                <>
                    {/* DESKTOP NAVIGATION */}
                    <Loaders name="home" isLoading={isLoading} />
                    <TopHeader />
                    <header className="mainHeader noPrint">
                        <div className="customContainer d-flex justify-content-between align-items-center h-100">
                            <div className="headLt d-flex justify-content-start align-items-center">
                                <Link to="/">
                                    <img src={DPW_LOGO} alt="DP World Auctions Logo" />
                                </Link>
                                {/* <div className="form-inline my-2 my-lg-0 headerSearchForm">
                                <input
                                    className="form-control"
                                    type="search"
                                    placeholder={t('search_dpworld_auction')}
                                    aria-label="Search"
                                    value={searchText}
                                    onChange={handleSearchTextChange}
                                    onKeyDown={handleSearchKeyDown}
                                />
                                <Button className="btn" onClick={handleSearchButtonClick}>
                                    <span className="material-icons">search</span>
                                </Button>
                            </div> */}
                            </div>
                            <div className="menuRtOtr">
                                <div className="headRt deskNav d-flex justify-content-between align-items-center">
                                    <ul className="d-flex justify-content-start align-items-center">
                                        <li>
                                            <NavLink activeClassName="active" to="/about">
                                                {t('about')}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/search">
                                                {t('products')}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/auction">
                                                {t('live_auctions')}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/contact">
                                                {t('contact')}
                                            </NavLink>
                                        </li>
                                    </ul>
                                    <ul className="d-flex justify-content-start align-items-center">
                                        {isAuthenticated ? (
                                            <>
                                                <li className="headCart">
                                                    <Button
                                                        onClick={() =>
                                                            handleRedirectInternal(
                                                                history,
                                                                'watchlist/watchlist',
                                                            )
                                                        }
                                                    >
                                                        <Badge color="primary">
                                                            <span className="material-icons">
                                                                favorite
                                                            </span>
                                                        </Badge>
                                                    </Button>
                                                </li>

                                                <li className="headCart">
                                                    {/* <Button
                                                    onClick={() =>
                                                        handleRedirectInternal(history, 'cart')
                                                    }
                                                >
                                            </Button> */}

                                                    <Button
                                                        aria-controls="simple-cart"
                                                        aria-haspopup="true"
                                                        onClick={handleCart}
                                                    >
                                                        <Badge
                                                            badgeContent={
                                                                count.buynowCount +
                                                                count.auctionCount
                                                            }
                                                            color="primary"
                                                        >
                                                            <span className="material-icons">
                                                                shopping_cart
                                                            </span>
                                                        </Badge>
                                                    </Button>
                                                    <Popover
                                                        id={'simple-cart'}
                                                        open={Boolean(anchorcart)}
                                                        anchorEl={anchorcart}
                                                        onClose={handleClose}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'right',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                    >
                                                        <ul
                                                            className={`headerDropMenu ${
                                                                document.body.dir === 'rtl' && 'rtl'
                                                            }`}
                                                        >
                                                            <ListItem
                                                                button
                                                                onClick={() =>
                                                                    handleClose(
                                                                        history,
                                                                        'buynowcart',
                                                                    )
                                                                }
                                                            >
                                                                <Badge
                                                                    badgeContent={count.buynowCount}
                                                                    color="primary"
                                                                >
                                                                    <span className="material-icons">
                                                                        shopping_bag
                                                                    </span>
                                                                </Badge>
                                                                {t('buy_now_cart')}
                                                            </ListItem>
                                                            <ListItem
                                                                button
                                                                onClick={() =>
                                                                    handleClose(history, 'cart')
                                                                }
                                                            >
                                                                <Badge
                                                                    badgeContent={
                                                                        count.auctionCount
                                                                    }
                                                                    color="primary"
                                                                >
                                                                    <span className="material-icons">
                                                                        gavel
                                                                    </span>
                                                                </Badge>

                                                                {t('auction_cart')}
                                                            </ListItem>
                                                        </ul>
                                                    </Popover>
                                                </li>
                                                <li>
                                                    <Button
                                                        aria-controls="simple-menu"
                                                        aria-haspopup="true"
                                                        onClick={handleMenu}
                                                    >
                                                        <span className="userInitials small">
                                                            {user?.first_name?.charAt(0)}
                                                            {user?.last_name?.charAt(0)}{' '}
                                                        </span>
                                                    </Button>

                                                    <Popover
                                                        id={'simple-menu'}
                                                        open={Boolean(anchormenu)}
                                                        anchorEl={anchormenu}
                                                        onClose={handleClose}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'right',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                    >
                                                        <ul
                                                            className={`headerDropMenu ${
                                                                document.body.dir === 'rtl' && 'rtl'
                                                            }`}
                                                        >
                                                            <ListItem
                                                                button
                                                                onClick={() =>
                                                                    handleClose(
                                                                        history,
                                                                        'auctions/all',
                                                                    )
                                                                }
                                                            >
                                                                <span className="material-icons">
                                                                    gavel
                                                                </span>
                                                                {t('my_bids')}
                                                            </ListItem>
                                                            <ListItem
                                                                button
                                                                onClick={() =>
                                                                    handleClose(history, 'wallet')
                                                                }
                                                            >
                                                                <span className="material-icons">
                                                                    account_balance_wallet
                                                                </span>
                                                                {t('my_wallet')}
                                                            </ListItem>
                                                            <ListItem
                                                                button
                                                                onClick={() =>
                                                                    handleClose(
                                                                        history,
                                                                        'account/profile',
                                                                    )
                                                                }
                                                            >
                                                                <span className="material-icons">
                                                                    person
                                                                </span>
                                                                {t('profile')}
                                                            </ListItem>
                                                            <ListItem
                                                                button
                                                                onClick={() => onLogOut()}
                                                            >
                                                                <span className="material-icons">
                                                                    power_settings_new
                                                                </span>
                                                                {t('logout')}
                                                            </ListItem>
                                                        </ul>
                                                    </Popover>
                                                </li>
                                            </>
                                        ) : (
                                            <>
                                                <li>
                                                    <NavLink to="/login" ActiveclassName="active">
                                                        {t('login') + ' / ' + t('register')}
                                                    </NavLink>
                                                </li>
                                                {/* <li>
                                                <NavLink
                                                    to="/registration"
                                                    activeClassName="active"
                                                    id="register"
                                                >
                                                    {t('register')}
                                                </NavLink>
                                            </li> */}
                                            </>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </header>
                </>
            ) : (
                <>
                    {/* MOBILE / TAB NAVIGATION */}
                    <header className="mainHeader customContainer d-flex justify-content-between align-items-center">
                        <div className="headLt d-flex justify-content-start">
                            <Link to="/">
                                <img src={DPW_LOGO} alt="DP World Auctions Logo" />
                            </Link>
                        </div>
                        <div className="headRt">
                            <ul className="d-flex justify-content-start ml-auto align-items-center">
                                <li>
                                    <Button onClick={toggleDrawer('top', true)}>
                                        <span className="material-icons">search</span>
                                    </Button>
                                </li>
                                {isAuthenticated && (
                                    <li className="headCart">
                                        <Link to="/cart">
                                            <Badge color="primary">
                                                <span className="material-icons">
                                                    shopping_cart
                                                </span>
                                            </Badge>
                                        </Link>
                                    </li>
                                )}
                                <li>
                                    <Button
                                        className="respNavBtn"
                                        onClick={toggleDrawer('right', true)}
                                    >
                                        <span className="material-icons">menu</span>
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </header>
                    <React.Fragment>
                        <SwipeableDrawer
                            className="respHeaderSearch"
                            anchor={'top'}
                            open={state['top']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('top', false)}
                            onOpen={toggleDrawer('top', true)}
                        >
                            <div className="form-inline my-2 my-lg-0 headerSearchForm">
                                <input
                                    className="form-control"
                                    type="search"
                                    placeholder={'search' + t('dp_world_auction')}
                                    aria-label="Search"
                                    value={searchText}
                                    onChange={handleSearchTextChange}
                                    onKeyDown={handleSearchKeyDown}
                                />
                                <Button className="btn" onClick={handleSearchButtonClick}>
                                    <span className="material-icons">search</span>
                                </Button>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>

                    <React.Fragment>
                        <SwipeableDrawer
                            className="headerDrawer"
                            anchor={'right'}
                            open={state['right']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('right', false)}
                            onOpen={toggleDrawer('right', true)}
                        >
                            <div className="headRt respNav d-flex justify-content-start align-items-center">
                                <div className="naLogoHead d-flex justify-content-between align-items-center">
                                    <Link to="/">
                                        <img
                                            src={DPW_LOGO}
                                            alt="DP World Auctions Logo"
                                            height="60"
                                        />
                                    </Link>
                                    <Button
                                        className="headDrawerClose"
                                        onClick={toggleDrawer('right', false)}
                                    >
                                        <span className="material-icons">clear</span>
                                    </Button>
                                </div>
                                <Divider />
                                <ul
                                    className="navRespLinks"
                                    onClick={(toggleDrawer('right', false), window.scrollTo(0, 0))}
                                >
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/auction">
                                            <span className="material-icons">live_tv</span>
                                            {t('live_auctions')}
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/search">
                                            <span className="material-icons">category</span>
                                            {t('products')}
                                        </NavLink>
                                    </ListItem>

                                    {isAuthenticated ? (
                                        <>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to={'/account/profile'}
                                                >
                                                    <span className="material-icons">person</span>
                                                    {t('profile')}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/auctions/all"
                                                >
                                                    <span className="material-icons">gavel</span>
                                                    {t('my_bids')}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/purchases/buynow"
                                                >
                                                    <span className="material-icons">
                                                        shopping_basket
                                                    </span>
                                                    {t('my_purchases')}
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/watchlist/watchlist"
                                                >
                                                    <span className="material-icons">favorite</span>
                                                    {t('favorites')}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/wallet">
                                                    <span className="material-icons">
                                                        account_balance_wallet
                                                    </span>
                                                    {t('my_wallet')}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/transactions"
                                                >
                                                    <span className="material-icons">
                                                        swap_vert
                                                    </span>
                                                    {t('my_transactions')}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/invoices/all"
                                                >
                                                    <span className="material-icons">receipt</span>
                                                    {t('my_invoices')}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/saved_search"
                                                >
                                                    <span className="material-icons">bookmark</span>
                                                    {t('my_saved_searches')}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/contact">
                                                    <span className="material-icons">
                                                        contact_support
                                                    </span>
                                                    {t('contact')}
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/about">
                                                    <span className="material-icons">info</span>
                                                    {t('about')}
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    ) : (
                                        <>
                                            <ListItem button>
                                                <NavLink to="/login" ActiveclassName="active">
                                                    <span className="material-icons">login</span>
                                                    {t('login')}
                                                </NavLink>
                                            </ListItem>
                                            {/* <ListItem button>
                                                <NavLink
                                                    to="/registration"
                                                    activeClassName="active"
                                                >
                                                    <span className="material-icons">
                                                        app_registration
                                                    </span>
                                                    {t('register')}
                                                </NavLink>
                                            </ListItem> */}
                                        </>
                                    )}
                                    <ListItem button>
                                        <a href="tel:048897585">
                                            <span className="material-icons">call</span>
                                            {t('within_uae')}:
                                            <span className="w-100 hnContact">04 889 7585</span>
                                        </a>
                                    </ListItem>
                                    <ListItem button>
                                        <a href="tel: +97148897585">
                                            <span className="material-icons">call</span>
                                            {t('international')}:
                                            <span className="w-100 hnContact">+971 4 889 7585</span>
                                        </a>
                                    </ListItem>
                                    {isAuthenticated && (
                                        <ListItem
                                            className="mt-3"
                                            button
                                            onClick={() => onLogOut()}
                                        >
                                            <span className="material-icons">
                                                power_settings_new
                                            </span>
                                            {t('logout')}
                                        </ListItem>
                                    )}
                                </ul>
                                <ul className="navRespLinks">
                                    <ListItem button>
                                        <LanguageSwitch />
                                    </ListItem>
                                </ul>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>
                </>
            )}
            <BidHistory />
        </>
    )
}
export default Header
