import React, { useEffect, useContext, Suspense } from 'react'
import { Route, BrowserRouter as Router, withRouter, Switch } from 'react-router-dom'
import PrivateRoute from './privateRoute'
import Loaders from '../custom/components/molecules/Loaders'

import commonContext from '../product/context/common/commonContext'
import AuthContext from '../product/context/auth/authContext'
import CustomCommonContext from '../custom/context/common/commonContext'
import ProductCommonContext from '../product/context/common/commonContext'

import Header from '../custom/components/molecules/Header'
import Footer from '../custom/components/molecules/Footer'
const Login = React.lazy(() => import('../views/Login'))

const ForgotPassword = React.lazy(() => import('../views/ForgotPassword'))
const ResetPassword = React.lazy(() => import('../views/ResetPassword'))

const Search = React.lazy(() => import('../views/Search'))
const MyAuctions = React.lazy(() => import('../views/Dashboard/MyAuctions'))
const Registration = React.lazy(() => import('../views/Registration'))
const IndividualRegistration = React.lazy(() =>
    import('../views/Registration/IndividualRegistration'),
)
const CompanyRegistration = React.lazy(() => import('../views/Registration/CompanyRegistration'))
const Profile = React.lazy(() => import('../views/Dashboard/Profile/index'))
const ChangePassword = React.lazy(() => import('../views/Dashboard/Profile/ChangePassword'))
const MyInvoices = React.lazy(() => import('../views/Dashboard/MyInvoices'))
const MyDeposits = React.lazy(() => import('../views/Dashboard/MyDeposits'))
const MyTransactions = React.lazy(() => import('../views/Dashboard/MyTransactions'))
const Invoice = React.lazy(() => import('../views/Invoice'))
const DepositInvoice = React.lazy(() => import('../views/DepositInvoice'))

const LiveAuction = React.lazy(() => import('../views/ProductView/LiveAuction'))
const ProductViewInvite = React.lazy(() => import('../views/ProductView/ProductView_Invite'))
const Home = React.lazy(() => import('../views/Home'))
const Cart = React.lazy(() => import('../views/Cart'))
const BuynowCart = React.lazy(() => import('../views/BuyNowCart'))
const Checkout = React.lazy(() => import('../views/Checkout'))

const MyWallet = React.lazy(() => import('../views/Dashboard/MyWallet'))
const ErrorPage = React.lazy(() => import('../views/ErrorPage'))
const Auction = React.lazy(() => import('../views/Auction'))
const VerifyEmail = React.lazy(() => import('../views/VerifyEmail'))

const Notifications = React.lazy(() => import('../views/Dashboard/Notifications'))
const ContactUs = React.lazy(() => import('../views/ContactUs'))
const StaticPage = React.lazy(() => import('../views/Static'))
const SavedSearch = React.lazy(() => import('../views/Dashboard/SavedSearch'))
const RosoomResponse = React.lazy(() => import('../views/Rosoom/Response'))

const Routes = () => {
    const authContext = useContext(AuthContext)
    const { loadUser, callEnd } = authContext
    const customCommonContext = useContext(CustomCommonContext)
    const productCommonContext = useContext(ProductCommonContext)

    const { getGlobalVariable } = customCommonContext
    const { getAllCountries, getAllStates } = productCommonContext
    const { loaderSet } = useContext(commonContext)

    useEffect(() => {
        loaderSet(true)
        if (localStorage.token) {
            loadUser(localStorage.token)
        } else {
            loaderSet(false)
        }

        getGlobalVariable()
        getAllCountries()
        getAllStates()
    }, [])

    useEffect(() => {
        if (callEnd) loaderSet(false)
    }, [callEnd])

    return (
        <>
            <Header />
            <Suspense fallback={<Loaders name="home" isLoading={true} />}>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/forgot_password" component={ForgotPassword} />
                    <Route exact path="/reset_password/:token" component={ResetPassword} />
                    <Route exact path="/registration" component={Registration} />
                    <Route
                        exact
                        path="/registration_individual"
                        component={IndividualRegistration}
                    />
                    <Route exact path="/registration_company" component={CompanyRegistration} />
                    <Route exact path="/search" component={Search} />
                    <Route exact path="/liveAuction" component={LiveAuction} />
                    <Route exact path="/auction" component={Auction} />
                    <Route exact path="/invite" component={ProductViewInvite} />
                    <Route exact path="/contact" component={ContactUs} />
                    <Route exact path="/saved_search" component={SavedSearch} />
                    <Route exact path="/verify_email/:token" component={VerifyEmail} />
                    <PrivateRoute exact path="/buynowcart" component={BuynowCart} />
                    <PrivateRoute exact path="/checkout/:type/:cart_id" component={Checkout} />
                    <PrivateRoute exact path="/cart" component={Cart} />
                    <PrivateRoute exact path="/auctions/:type" component={MyAuctions} />
                    <PrivateRoute exact path="/watchlist/:type" component={MyAuctions} />
                    <PrivateRoute exact path="/purchases/:type" component={MyAuctions} />
                    <PrivateRoute exact path="/account/profile" component={Profile} />
                    <PrivateRoute exact path="/account/change" component={ChangePassword} />
                    <PrivateRoute exact path="/account/notifications" component={Notifications} />
                    {/* <PrivateRoute exact path="/company_profile" component={CompanyProfile} /> */}
                    <PrivateRoute exact path="/invoices/:action" component={MyInvoices} />
                    <PrivateRoute exact path="/transactions" component={MyTransactions} />
                    <PrivateRoute exact path="/invoice/:id" component={Invoice} />
                    <PrivateRoute exact path="/depositinvoice/:id" component={DepositInvoice} />
                    <PrivateRoute exact path="/deposits/:action" component={MyDeposits} />
                    <PrivateRoute exact path="/wallet" component={MyWallet} />
                    <PrivateRoute exact path="/rosoom_success" component={RosoomResponse} />
                    <Route
                        exact
                        path={[
                            '/about',
                            '/services',
                            '/help',
                            '/guidelines',
                            '/privacy_policy',
                            '/terms_of_use',
                        ]}
                        component={StaticPage}
                    />

                    <Route exact path="*" component={ErrorPage} />
                </Switch>
                <Footer />
            </Suspense>
            {/* <Suspense fallback={<Loaders name="home" />}> */}
            {/* </Suspense> */}
        </>
    )
}
export default withRouter(Routes)
