import { RESPONSE_STATUS, CLEAR_RESPONSE, GET_ALL_USER_CREDIT } from './creditTypes'
export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_ALL_USER_CREDIT:
            return {
                ...state,
                search_allusercredit: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
