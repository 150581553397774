import React, { useReducer } from 'react'
import PayContext from './payContext'
import PayReducer from './payReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'
import { RESPONSE_STATUS, CLEAR_RESPONSE } from './payTypes'

const PayState = (props) => {
    const initialState = {
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(PayReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const payCart = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'authorize', formData, '', 'payment')])
            resp.commonResponse(res.data, 'payment')
        } catch (err) {
            resp.commonErrorResponse('payment')
        }
    }

    const payCartRosoom = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'rosoom_payment', formData, '', 'payment'),
            ])
            resp.commonResponse(res.data, 'payment')
        } catch (err) {
            resp.commonErrorResponse('payment')
        }
    }

    const responseCartRosoom = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'rosoom_response', formData, '', 'payment'),
            ])
            resp.commonResponse(res.data, 'rosoomResponse')
        } catch (err) {
            resp.commonErrorResponse('rosoomResponse')
        }
    }
    const payResponsePaypal = async (formData) => {
        try {
            console.log('payResponsePaypal', formData)
            const [res] = await Promise.all([
                apiCall('post', 'paypal_response', formData, '', 'payment'),
            ])
            resp.commonResponse(res.data, 'payment')
        } catch (err) {
            resp.commonErrorResponse('payment')
        }
    }

    const payCartStripe = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'stripe_payment', formData, '', 'payment'),
            ])
            resp.commonResponse(res.data, 'payment')
        } catch (err) {
            resp.commonErrorResponse('payment')
        }
    }

    const payCartCyberSource = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'cybersource_payment', formData, '', 'payment'),
            ])
            resp.commonResponse(res.data, 'payment')
        } catch (err) {
            resp.commonErrorResponse('payment')
        }
    }

    const payCartNorthCapital = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'northcapital_payment', formData, '', 'payment'),
            ])
            resp.commonResponse(res.data, 'payment')
        } catch (err) {
            resp.commonErrorResponse('payment')
        }
    }

    const payTMMembershipPayment = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'membership-payment', formData, '', 'paymentThird/paytm'),
            ])
            resp.commonResponse(res.data, 'payment_paytm_membership')
        } catch (err) {
            resp.commonErrorResponse('payment_paytm_membership')
        }
    }

    const responseCartPayTm = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'paytm_response', formData, '', 'paymentThird/paytm'),
            ])
            resp.commonResponse(res.data, 'paytmResponse')
        } catch (err) {
            resp.commonErrorResponse('paytmResponse')
        }
    }

    const cashFreeMembershipPayment = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'membership-payment', formData, '', 'paymentThird/cashfree'),
            ])
            resp.commonResponse(res.data, 'payment_cashfree_membership')
        } catch (err) {
            resp.commonErrorResponse('payment_cashfree_membership')
        }
    }

    const cashFreeResponseCart = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'cashfree_response', formData, '', 'paymentThird/cashfree'),
            ])
            resp.commonResponse(res.data, 'cashfreeResponse')
        } catch (err) {
            resp.commonErrorResponse('cashfreeResponse')
        }
    }

    const payCartPaytm = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'paytm_payment', formData, '', 'payment'),
            ])
            resp.commonResponse(res.data, 'paytm_payment')
        } catch (err) {
            resp.commonErrorResponse('paytm_payment')
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <PayContext.Provider
            value={{
                responseStatus: state.responseStatus,
                payCart,
                payCartRosoom,
                payCartStripe,
                payResponsePaypal,
                payCartNorthCapital,
                payTMMembershipPayment,
                cashFreeResponseCart,
                cashFreeMembershipPayment,
                responseCartRosoom,
                responseCartPayTm,
                clearResponse,
                payCartPaytm,
                payCartCyberSource,
            }}
        >
            {props.children}
        </PayContext.Provider>
    )
}

export default PayState
