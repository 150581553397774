import React, { useReducer } from 'react'
import OfflineContext from './offlineContext'
import OfflineReducer from './offlineReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import { CLEAR_RESPONSE, RESPONSE_STATUS } from './offlineTypes'

const OfflineState = (props) => {
    const initialState = {
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(OfflineReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const payCartOffline = async (formData) => {
        const from = 'payCartOffline'
        try {
            const [res] = await Promise.all([apiCall('post', from, formData, '', 'offline')])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <OfflineContext.Provider
            value={{
                responseStatus: state.responseStatus,
                payCartOffline,
                clearResponse,
            }}
        >
            {props.children}
        </OfflineContext.Provider>
    )
}

export default OfflineState
