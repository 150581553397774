import React, { useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import CommonContext from './commonContext'
import CommonReducer from './commonReducer'
import { apiCall } from '../../../product/common/api'
import { response } from '../../../product/context/common'

import { COMMON_VALUES, RESPONSE_STATUS, CLEAR_RESPONSE, SWITCH_LANGUAGE } from './commonTypes'

const CommonState = (props) => {
    const initialState = {
        allCategory: [],
        allBusinessCategory: [],
        allVatCategory: [],
        allLocations: [],
        allCondition: [],
        allSubCategories: [],
        allNotifications: [],
        allConfigurationVariables: {},
        configFeatures: {},
        configVariables: {},
        allBidIncrements: [],
        responseStatus: null,
        languages: [],
        pharases: {},
        currentLanguage: 'for_english',
    }

    const { i18n } = useTranslation()

    const [state, dispatch] = useReducer(CommonReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getGlobalVariable = async (type) => {
        const [res] = await Promise.all([apiCall('post', 'getDefault', {}, '', 'common')])
        if (res?.data?.status && res.data.status === 'success') {
            dispatch({
                type: COMMON_VALUES,
                payload: {
                    allCategory: res.data.data.responseData.tableValues.allcategoryTypes,
                    allSubCategories: res.data.data.responseData.tableValues.allsubCategoryTypes,
                    allBusinessCategory:
                        res.data.data.responseData.tableValues.allbusiness_category,
                    allVatCategory: res.data.data.responseData.tableValues.allvat_registration,
                    allNotifications: res.data.data.responseData.tableValues.allall_notifications,
                    allConfigurationVariables: res.data.data.responseData.variables,
                    allLocations: res.data.data.responseData.tableValues.alllocations,
                    allCondition: res.data.data.responseData.tableValues.allconditionTypes,
                    allBidIncrements: res.data.data.responseData.allBidIncrements,
                    bidIncrementDefault: res.data.data.responseData.bidIncrementDefault,
                    configFeatures: res.data.data.responseData.features,
                    configVariables: res.data.data.responseData.variables,
                    languages: res.data.data.responseData.languages,
                    phrases: res.data.data.responseData.phrases,
                },
            })

            //set reload the language phrase
            global.languages = res.data.data.responseData.languages
            global.phrases = res.data.data.responseData.phrases
            for (let i = 0; i < global.languages.length; i++) {
                i18n.addResourceBundle(
                    global.languages[i],
                    'translations',
                    global.phrases[global.languages[i]],
                    true,
                    true,
                )
            }
            i18n.changeLanguage('for_english')
        }
    }

    const switchLanguage = (data) =>
        dispatch({
            type: SWITCH_LANGUAGE,
            payload: data,
        })

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <CommonContext.Provider
            value={{
                allCategory: state.allCategory,
                allBusinessCategory: state.allBusinessCategory,
                allVatCategory: state.allVatCategory,
                allNotifications: state.allNotifications,
                allSubCategories: state.allSubCategories,
                allLocations: state.allLocations,
                allCondition: state.allCondition,
                allBidIncrements: state.allBidIncrements,
                allConfigurationVariables: state.allConfigurationVariables,
                configFeatures: state.configFeatures,
                configVariables: state.configVariables,
                languages: state.languages,
                currentLanguage: state.currentLanguage,
                responseStatus: state.responseStatus,
                switchLanguage,
                getGlobalVariable,
                clearResponse,
            }}
        >
            {props.children}
        </CommonContext.Provider>
    )
}

export default CommonState
