import React, { useReducer } from 'react'
import DepositContext from './depositContext'
import DepositReducer from './depositReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    GET_ALL_DEPOSIT,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_ALL_USER_DEPOSITS,
} from './depositTypes'

const DepositState = (props) => {
    const initialState = {
        search_alldepositproducts: {
            invoicerecords: [],
        },
        search_alluserdeposits: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            from: '',
        },
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(DepositReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAllDepositProducts = async (formData) => {
        const from = 'deposit'
        try {
            const [res] = await Promise.all([apiCall('post', from, formData, '', 'deposit')])

            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_DEPOSIT,
                    payload: {
                        invoicerecords: res.data.data.responseData.invoiceItems.length
                            ? res.data.data.responseData.invoiceItems
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getAllUserDeposits = async (formData, fromVariable) => {
        const from = 'alldeposits'
        try {
            const [res] = await Promise.all([apiCall('post', from, formData, '', 'deposit')])

            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_USER_DEPOSITS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from: fromVariable,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <DepositContext.Provider
            value={{
                search_alldepositproducts: state.search_alldepositproducts,
                search_alluserdeposits: state.search_alluserdeposits,
                responseStatus: state.responseStatus,
                clearResponse,
                getAllDepositProducts,
                getAllUserDeposits,
            }}
        >
            {props.children}
        </DepositContext.Provider>
    )
}

export default DepositState
