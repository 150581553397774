import React, { useState } from 'react'
import './Header.css'
import LanguageSwitch from '../LanguageSwitch'
import { useTranslation } from 'react-i18next'

const TopHeader = () => {
    const { t } = useTranslation()
    return (
        <div className="topHeader d-flex justify-content-end align-items-center noPrint">
            <ul className="customContainer d-flex justify-content-end align-items-center">
                <li>
                    <a href="tel:048897585">
                        {t('within_uae')}: <span dir="ltr"> &nbsp; 04 889 7585</span>
                        <span className="linkDivider">|</span>
                    </a>
                </li>
                <li>
                    <a href="tel: +97148897585">
                        {t('international')}: <span dir="ltr"> +971 4 889 7585</span>
                        <span className="linkDivider">|</span>
                    </a>
                </li>
                <li>
                    <LanguageSwitch />
                </li>
            </ul>
        </div>
    )
}
export default TopHeader
