import { CART_PENDING, CHECKOUT_PENDING, RESPONSE_STATUS, CLEAR_RESPONSE } from './rosoomTypes'
export default (state, action) => {
    switch (action.type) {
        case CART_PENDING:
            return {
                ...state,
                cart_pending_payment: action.payload,
            }
        case CHECKOUT_PENDING:
            return {
                ...state,
                checkout_pending_payment: action.payload,
            }
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
