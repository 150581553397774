import { setAuthToken } from '../common/api'

export class response {
    constructor(dispatch, RESPONSE_STATUS) {
        this.dispatch = dispatch
        this.RESPONSE_STATUS = RESPONSE_STATUS
    }

    async commonErrorResponse(from, noAlert) {
        this.dispatch({
            type: this.RESPONSE_STATUS,
            payload: {
                status: 'error',
                message: 'Something went wrong!',
                type: 0,
                from: from,
                noAlert,
            },
        })
    }

    async commonResponse(data, from, noAlert) {
        if (data?.status && data.status === 'success') {
            this.dispatch({
                type: this.RESPONSE_STATUS,
                payload: {
                    status: data.status,
                    message: data.data.message,
                    type: data.data.responseType,
                    data: data.data.responseData,
                    from: from,
                    noAlert,
                },
            })
        } else if (data?.status && data.status === 'error') {
            if (data.data.responseType === 403) {
                this.dispatch({
                    type: 'LOGOUT',
                })
                setAuthToken(false)
                window.location.href = '/login'
            }
            this.dispatch({
                type: this.RESPONSE_STATUS,
                payload: {
                    status: data.status,
                    message: data.data.message,
                    type: data.data.responseType,
                    from: from,
                    noAlert,
                },
            })
        } else {
            this.commonErrorResponse(from)
        }
    }
}
