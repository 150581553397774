import { RESPONSE_STATUS, CLEAR_RESPONSE, GET_AVAILABLE_APPOINTMENT } from './appointmentTypes'
export default (state, action) => {
    switch (action.type) {
        case GET_AVAILABLE_APPOINTMENT:
            return {
                ...state,
                available_appointments: action.payload,
            }

        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
