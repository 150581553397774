import React, { useReducer } from 'react'
import AuctionContext from './auctionContext'
import AuctionReducer from './auctionReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import { GET_ALL_SEARCH, RESPONSE_STATUS, CLEAR_RESPONSE, GET_ALL_DASHBOARD } from './auctionTypes'

const AuctionState = (props) => {
    const initialState = {
        search_allauctions: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            from: '',
        },
        search_alldashboardauctions: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            sorts: {},
        },
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(AuctionReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAllAuctionProducts = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'search', formData, '', 'auction')])
            const from = 'search'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_SEARCH,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from: fromVariable,
                    },
                })
            } else if (res.data.status === 'error') {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res.data.status,
                        message: res.data.data.message,
                        type: res.data.data.responseType,
                        from: from,
                    },
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const addWatchlistauction = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'addAuctionWatchList', formData, '', 'auction'),
            ])
            resp.commonResponse(res.data, 'auctionwatchlist')
        } catch (err) {
            resp.commonErrorResponse('auctionwatchlist')
        }
    }

    const removeWatchlistauction = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'removeAuctionWatchList', formData, '', 'auction'),
            ])
            resp.commonResponse(res.data, 'auctionwatchlist')
        } catch (err) {
            resp.commonErrorResponse('auctionwatchlist')
        }
    }
    const getAllDashboardwatchlist = async (formData, from) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'auctionWatchList', formData, '', 'auction'),
            ])
            let fromValue = from || 'displaywatchlist'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_DASHBOARD,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        sorts: res.data.data.responseData.sorts,
                        from: fromValue,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllDashboardAuctions = async (formData, from) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'myBids', formData, '', 'auction')])
            const fromValue = from || 'dashboard'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_DASHBOARD,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        sorts: res.data.data.responseData.sorts,
                        from: fromValue,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <AuctionContext.Provider
            value={{
                // buyer_allproducts: state.buyer_allproducts,
                search_allauctions: state.search_allauctions,
                responseStatus: state.responseStatus,
                search_alldashboardauctions: state.search_alldashboardauctions,
                clearResponse,
                getAllAuctionProducts,
                getAllDashboardAuctions,
                addWatchlistauction,
                getAllDashboardwatchlist,
                removeWatchlistauction,
            }}
        >
            {props.children}
        </AuctionContext.Provider>
    )
}

export default AuctionState
