import React, { useReducer } from 'react'
import RosoomContext from './rosoomContext'
import RosoomReducer from './rosoomReducer'
import { apiCall } from '../../../common/api'
import { response } from '../../common'

import { CART_PENDING, CHECKOUT_PENDING, CLEAR_RESPONSE, RESPONSE_STATUS } from './rosoomTypes'

const RosoomState = (props) => {
    const initialState = {
        responseStatus: null,
        cart_pending_payment: null,
        checkout_pending_payment: null,
    }

    const [state, dispatch] = useReducer(RosoomReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getPendingTransaction = async (formData) => {
        const from = 'getPendingTransaction'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/rosoom'),
            ])
            if (res.data.status === 'success') {
                await dispatch({
                    type: CHECKOUT_PENDING,
                    payload: res.data.data.responseData,
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getPendingOnCartTransaction = async (formData) => {
        const from = 'getPendingOnCartTransaction'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/rosoom'),
            ])
            if (res.data.status === 'success') {
                await dispatch({
                    type: CART_PENDING,
                    payload: res.data.data.responseData,
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <RosoomContext.Provider
            value={{
                responseStatus: state.responseStatus,
                cart_pending_payment: state.cart_pending_payment,
                checkout_pending_payment: state.checkout_pending_payment,
                getPendingTransaction,
                getPendingOnCartTransaction,
                clearResponse,
            }}
        >
            {props.children}
        </RosoomContext.Provider>
    )
}

export default RosoomState
