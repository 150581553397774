import React, { useReducer } from 'react'
import ProductContext from './productContext'
import ProductReducer from './productReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    GET_ALL_INVOICE,
    GET_ALL_SEARCH,
    GET_ALL_SIMILAR,
    GET_ALL_BID_HISTORY,
    GET_ALL_TTWBID_HISTORY,
    GET_ALL_ACTIVE_SORTS,
    GET_ALL_DASHBOARD,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_ALL_USER_INVOICES,
    GET_ALL_USER_RETURNS,
    GET_ALL_RETURN_INVOICE,
    GET_SAVED_SEARCHES,
    TTWBID_HISTORY_BY_USER,
    GET_ALL_PRODUCTS,
    GET_FOLLOWERS,
} from './productTypes'

const ProductState = (props) => {
    const initialState = {
        search_alldashboardproducts: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            sorts: {},
        },
        active_sorts: {
            sorts: null,
        },
        search_allsimilar: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        search_allproducts: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            sorts: {},
        },
        search_allbidhistory: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            sorts: {},
        },
        search_allttwbidhistory: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            sorts: {},
        },
        search_allinvoiceproducts: {
            invoicerecords: [],
            transactionrecords: [],
            appointmentrecord: {},
            locationrecord: {},
            cartvalues: {},
        },
        search_allreturninvoiceproducts: {
            invoicerecords: [],
            transactionrecords: [],
            appointmentrecord: {},
            locationrecord: {},
            cartvalues: {},
        },
        search_alluserinvoices: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        search_alluserreturns: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        saved_searches: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        followers: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        ttwBidsByUser: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        listingFromAdmin: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(ProductReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAllDashboardProducts = async (formData, from) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'dashboard', formData, '', 'product')])
            let formvalue = from || 'search'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_DASHBOARD,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        sorts: res.data.data.responseData.sorts,
                        from: formvalue,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getTtwAllDashboardProducts = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'ttw_dashboard', formData, '', 'product'),
            ])
            const from = 'search'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_DASHBOARD,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        sorts: res.data.data.responseData.sorts,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllInvoiceProducts = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'invoice', formData, '', 'product')])
            const from = 'invoicesearch'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_INVOICE,
                    payload: {
                        invoicerecords: res.data.data.responseData.invoiceItems.length
                            ? res.data.data.responseData.invoiceItems
                            : [],
                        transactionrecords: res.data.data.responseData.allTransactions.length
                            ? res.data.data.responseData.allTransactions
                            : [],
                        appointmentrecord: res.data.data.responseData.appointmentDetail,
                        locationrecord: res.data.data.responseData.locationDetail,
                        cartvalues: res.data.data.responseData.cartValues,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllReturnInvoiceProducts = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'returninvoice', formData, '', 'product'),
            ])
            const from = 'invoicesearch'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_RETURN_INVOICE,
                    payload: {
                        invoicerecords: res.data.data.responseData.invoiceItems.length
                            ? res.data.data.responseData.invoiceItems
                            : [],
                        transactionrecords: res.data.data.responseData.allTransactions.length
                            ? res.data.data.responseData.allTransactions
                            : [],
                        appointmentrecord: res.data.data.responseData.appointmentDetail,
                        locationrecord: res.data.data.responseData.locationDetail,
                        cartvalues: res.data.data.responseData.cartValues,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllUserInvoices = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'allinvoices', formData, '', 'product'),
            ])
            const from = 'allinvoices'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_USER_INVOICES,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('cartsearch')
        }
    }

    const getAllUserReturns = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'allreturns', formData, '', 'product'),
            ])
            const from = 'allreturns'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_USER_RETURNS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('cartsearch')
        }
    }

    const getAllSearchProducts = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'search', formData, '', 'product')])
            const from = fromVariable ? fromVariable : 'search'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_SEARCH,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        sorts: res.data.data.responseData.sorts,
                        from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllActiveSorts = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'activeSorts', formData, '', 'product'),
            ])
            const from = 'search'
            if (res.data.status === 'success') {
                const respData = res.data.data.responseData.sorts
                dispatch({
                    type: GET_ALL_ACTIVE_SORTS,
                    payload: {
                        sorts: respData,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllSimilarProducts = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'search', formData, '', 'product')])
            const from = 'similar'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_SIMILAR,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const addWatchlist = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'addWatchlist', formData, '', 'user')])
            resp.commonResponse(res.data, 'addWatchlist')
        } catch (err) {
            resp.commonErrorResponse('addWatchlist')
        }
    }

    const removeWatchlist = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'removeWatchlist', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'removeWatchlist')
        } catch (err) {
            resp.commonErrorResponse('removeWatchlist')
        }
    }

    const addSavedSearch = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'addSavedSearch', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'saved_search_msg')
        } catch (err) {
            resp.commonErrorResponse('saved_search_msg')
        }
    }

    const getSavedSearch = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSavedSearch', formData, '', 'user'),
            ])
            dispatch({
                type: GET_SAVED_SEARCHES,
                payload: {
                    records: res.data.data.responseData.records.length
                        ? res.data.data.responseData.records
                        : [],
                    totalRecords: res.data.data.responseData.totalRecords,
                    setDisp: res.data.data.responseData.setDisp,
                },
            })
        } catch (err) {
            resp.commonErrorResponse('saved_search')
        }
    }

    const removeSavedSearch = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'removeSavedSearch', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'saved_search_msg')
        } catch (err) {
            resp.commonErrorResponse('saved_search_msg')
        }
    }

    const getFollowers = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'getFollowers', formData, '', 'user')])
            dispatch({
                type: GET_FOLLOWERS,
                payload: {
                    records: res.data.data.responseData.records.length
                        ? res.data.data.responseData.records
                        : [],
                    totalRecords: res.data.data.responseData.totalRecords,
                    setDisp: res.data.data.responseData.setDisp,
                },
            })
        } catch (err) {
            resp.commonErrorResponse('follower')
        }
    }

    const addFollower = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'addFollower', formData, '', 'user')])
            resp.commonResponse(res.data, 'add_follower_msg')
        } catch (err) {
            resp.commonErrorResponse('add_follower_msg')
        }
    }

    const removeFollower = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'removeFollower', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'remove_follower_msg')
        } catch (err) {
            resp.commonErrorResponse('remove_follower_msg')
        }
    }

    const updateFollower = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateFollower', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'update_follower_msg')
        } catch (err) {
            resp.commonErrorResponse('update_follower_msg')
        }
    }

    const getAllBidHistory = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'bidhistory', formData, '', 'product'),
            ])
            const from = 'search'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_BID_HISTORY,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        sorts: res.data.data.responseData.sorts,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllTtwBidHistory = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'ttwbidhistory', formData, '', 'product'),
            ])
            const from = 'search'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_TTWBID_HISTORY,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        sorts: res.data.data.responseData.sorts,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllTtwBidHistoryByUser = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'ttwbidhistory', formData, '', 'product'),
            ])
            const from = 'ttwBidHistoryByUser'
            if (res.data.status === 'success') {
                dispatch({
                    type: TTWBID_HISTORY_BY_USER,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        sorts: res.data.data.responseData.sorts,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getTrackChart = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getTrackChartMetric', formData, '', 'chartmetric'),
            ])
            resp.commonResponse(res.data, 'track_chart')
        } catch (err) {
            resp.commonErrorResponse('track_chart')
        }
    }

    const getAllProductsFromAdmin = async (formData, from) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'listings', formData, '', 'admin/product'),
            ])
            from = from ? from : 'listingFromAdmin'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_PRODUCTS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from: from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <ProductContext.Provider
            value={{
                // buyer_allproducts: state.buyer_allproducts,
                search_allproducts: state.search_allproducts,
                search_alldashboardproducts: state.search_alldashboardproducts,
                search_allreturninvoiceproducts: state.search_allreturninvoiceproducts,
                search_allinvoiceproducts: state.search_allinvoiceproducts,
                search_alluserinvoices: state.search_alluserinvoices,
                search_alluserreturns: state.search_alluserreturns,
                active_sorts: state.active_sorts,
                search_allbidhistory: state.search_allbidhistory,
                search_allttwbidhistory: state.search_allttwbidhistory,
                search_allsimilar: state.search_allsimilar,
                responseStatus: state.responseStatus,
                saved_searches: state.saved_searches,
                followers: state.followers,
                ttwBidsByUser: state.ttwBidsByUser,
                listingFromAdmin: state.listingFromAdmin,
                getAllTtwBidHistory,
                clearResponse,
                getAllDashboardProducts,
                getTtwAllDashboardProducts,
                getAllSearchProducts,
                getAllInvoiceProducts,
                getAllReturnInvoiceProducts,
                getAllUserInvoices,
                getAllUserReturns,
                getAllSimilarProducts,
                addWatchlist,
                removeWatchlist,
                getAllActiveSorts,
                getAllBidHistory,
                addSavedSearch,
                getSavedSearch,
                removeSavedSearch,
                getFollowers,
                addFollower,
                removeFollower,
                updateFollower,
                getTrackChart,
                getAllTtwBidHistoryByUser,
                getAllProductsFromAdmin,
            }}
        >
            {props.children}
        </ProductContext.Provider>
    )
}

export default ProductState
