import {
    GET_ALL_DEPOSIT,
    GET_ALL_USER_DEPOSITS,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
} from './depositTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_ALL_USER_DEPOSITS:
            return {
                ...state,
                search_alluserdeposits: action.payload,
            }
        case GET_ALL_DEPOSIT:
            return {
                ...state,
                search_alldepositproducts: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
